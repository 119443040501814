<template>
  <div
    class=" font-weight-light"
    :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
  >
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Roboto"> Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <span class="subhead">A</span> chemical equation is a way of representing a chemical reaction using symbols (to represent chemical entities) and numeric coefficients (to represent stoichiometry) of the various chemical entities involved in the reaction.
    <div style="margin-top: 20px" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Roboto"> Reactants & Products</span>
    </div>
    <div ref="ia" style="margin-top: 10px" />
    <ul class="a">
      <li><h5>Reactants</h5></li>
      The chemical entities undergoing a reaction are called reactants. By convention, the reactants of a reaction are shown on the left-hand side (LHS) of the chemical equation.<br>
      <li><h5>Products</h5></li>The chemical entities generated as a result of a reaction are called products. By convention, the products are shown on the right-hand side(RHS) of the chemical equation.
    </ul>
    <div style="margin-top: 10px" />
    For example, the chemical equation representing synthesis of ammonia is given as: $$ N_2 + 3H_2 \rightarrow 2NH_3$$
    In this reaction, \(N_2\)(Nitrogen) and \(H_2\) (Hydrogen) are the reactants, and \(NH_3\) (Ammonia) is the product. During the reaction, 1 molecule of \(N_2\) reacts with 3 molecules of \(H_2\) to produce 2 molecules of \(NH_3\).
    <div style="margin-top: 10px" />
    It is a common practice to use the smallest whole numbers as coefficients in a chemical equation &mdash; as shown in this example.
    <div style="margin-top: 20px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm8
              md8
              lg8
              xl8
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <div class="font-weight-medium"
             :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Roboto"> Balancing a Chemical Equation</span>
        </div>
        <div ref="ib" style="margin-top: 10px" />
        In a chemical reaction, matter is neither created nor destroyed, therefore every chemical reaction should be consistent with the law of conservation of mass.<br><br>
        This requires that the mass of every chemical species involved in the reaction should remain the same before and after the reaction. When a chemical equation satisfies this condition, the equation is said to be "balanced," otherwise, the equation is said to be "unbalanced."
        <div style="margin-top: 10px" />
        Let's take the example of the reaction describing the synthesis of water molecules from a combination of hydrogen and oxygen molecules.
        $$ H_2 + O_2 \rightarrow H_2 O$$ Let's check if this equation is balanced or unbalanced.
        <br> On the reactant side (shown on the left) &mdash; we have 2 Hydrogen atoms and 2 Oxygen atoms.
        <div style="margin-top: 10px" />
        On the product side (shown on the right) &mdash; we have 2 Hydrogen atoms, however, only 1 Oxygen atom.
        <div style="margin-top: 10px" />
        So, clearly the equation is unbalanced.
        <div style="margin-top: 10px" />
        Let's multiply the right side by a factor of 2 so that we now have 2 Oxygen atoms on each side. $$ H_2 + O_2 \rightarrow 2 H_2 O$$
        However, now we have 4 Hydrogen atoms on the product side and only 2 of those in the reactant side. So, in order to balance the number of Hydrogen atoms, we multiply \(H_2 \) on the reactant side with 2, and that makes the equation balanced.
        $$ 2 H_2 + O_2 \rightarrow 2 H_2 O$$.
      </v-flex>

      <v-flex xs12
              sm4
              md4
              lg4
              xl4
      >
        <v-img class="mx-auto px-2"
               padding="10px"
               max-height="600px"
               max-width="600px"
               src="/assets/Dalton.jpg"
               contain
        />
        <br>
        <h5 style="text-align:start">
          Lavoisier discovered the law of conservation of mass, which states that the mass remains conserved during a chemical reaction. This principle forms the basis for balancing chemical equations.
        </h5>
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Roboto"> Lesson Plan </span>
    </div>
    <div ref="ic" style="margin-top: 10px" />
    This chapter on balancing chemical equations contains three interactive lessons. Each lesson includes a visually interactive exercise that offers students a uniquely engaging and effective learning experience.
    <div style="margin-top: 10px" />
    <h5> Lesson 1 | Combustion of Hydrocarbons </h5>
    In this lesson, you will learn to balance a chemical equation representing combustion of a  hydrocarbon (such as Methane, Ethane, Propane or Butane).
    <div style="margin-top: 10px" />
    <h5> Lesson 2 | Chlorination of Metals </h5>
    This lesson includes visually interactive aids on balancing chemical equations describing chlorination of metals.
    <div style="margin-top: 10px" />
    <h5> Lesson 3 | Oxidation of Metals </h5>
    This lesson includes visually interactive aids on balancing chemical equations representing oxidation of metals.
    <br><br>

    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Roboto"> Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    Check out our library of meticulously crafted lessons on balancing chemical equations. These specially designed lessons use visuo-spatiality and interactivity to help students improve engagement and retain concepts.
    <div ref="id" style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :subheading="topic.subheading"
            :subsubheading="topic.subsubheading"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../SubTopics.vue'
export default {
    name: 'ChemicalEquations',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
        preload:'auto',
        ratio: '16:9'
        },
      topics: [
      {
        headline: 'Lesson 1',
//        subheading:'of',
//        subsubheading:'Hydrocarbons',
        description: "Learn to read and create bar charts",
        img:"/assets/chemistry-1.svg",
        path: "/chemistry/combustion"
      },
      { headline: 'Lesson 2',
  //      subheading:'of',
  //      subsubheading:'Metals',
        description: "Learn to read and create pie charts",
        img: "/assets/chemistry-2.svg",
        path: "/chemistry/rust"
      },
      { headline: 'Lesson 3',
  //      subheading:'of',
  //      subsubheading:'Metals',
        description: "Learn to read and create pictographs",
        img: "/assets/Chem.svg",
        path: "/chemistry/photosynthesis"
      },
      ]
    }
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  },
    created () {
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'Balancing a Chemical Equation');
      this.$store.commit('navigation/changeMenu', 'Balancing a Chemical Equation');
      let newTopics = [
        {title: 'Introduction', img:'/assets/wdice-1.svg', action: () => this.goto('ia')},
        {title: 'Balancing a Chemical Equation', img:'/assets/wdice-2.svg', action: () => this.goto('ib')},
        {title: 'Lesson Plan',img:'/assets/wdice-3.svg', action: () => this.goto('ic')},
        {title: 'Get Started',img:'/assets/wdice-4.svg', action: () => this.goto('id')}
      //  {title: 'Pythagorean Theorem', img:'/assets/wdice-4.svg', action: () => this.$router.push({path: '/mathematics/triangles/pythagorean'})},
      ];
      this.$store.commit('navigation/replaceTopics', newTopics);
      this.$store.commit('navigation/toggleshowhome', false);
      this.$store.commit('navigation/replaceChem', true);
      this.$store.commit('navigation/replaceLeftArrow', true);
      this.$store.commit('navigation/replaceModule', true);
      this.$store.commit('navigation/replaceSample', true);
       },
       metaInfo() {
       return{ title: 'Balancing a Chemical Equation',
               titleTemplate: '%s | Learn interactively',
               meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                       {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                     ]
             }
        }
}
</script>
<style scoped lang="scss">
@import 'src/styles/subtopic-menu.scss';
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
